import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography, useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import registerService from "../../services/register.service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import NDIlogobg from "../../assets/ndi/NDIlogobg.png";
import SignUpNDIScanCode from "../../NDI/SignUpNDIScanCode";
import NdiServices from "../../services/ndi.service";
import { useTranslation, i18next } from "react-i18next";

const SignUpPersonalDetail = ({
  personalData,
  setPersonalData,
  setDisableNextBtn,
}) => {
  const { t, i18n } = useTranslation();
  const [url, setUrl] = useState("");
  const [progressNDI, setProgressNDI] = useState(true);
  const [ndiSignupScanOpen, setNdiSignupOpen] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  //To call ndi proof verification
  const proofNdiRequest = () => {
    NdiServices.proofNdiRequestSignUp().then((res) => {
      setUrl(res.data.inviteURL);
      setProgressNDI(false);
      setDeepLinkUrl(res.data.deepLinkURL);
      natsListener(res.data.threadId);
    });
  };


  async function natsListener(threadId) {
    let endPoint =
      BASE_URL + "api/user/profile/signup/subscribe?threadId=" + threadId;
    let eventSource = new EventSource(endPoint);
    eventSource.addEventListener("NDI_SSI_EVENT", (event) => {
      setNdiSignupOpen(false);
      let data = JSON.parse(event.data);
          if (data.status === "exists") {
        setPersonalData({
          ...personalData,
          fullName: data.data.fullName,
          gender: data.data.genderName,
          cid: data.data.cidNumber,
          dob: data.data.dob.toString(),
          fatherName: data.data.fatherName,
          fatherCid: data.data.fatherCid,
          motherName: data.data.motherName,
          motherCid: data.data.motherCid,
          villageName: data.data.village,
          geogName: data.data.gewog,
          dzongkhagName: data.data.dzongkhag,
        });
        setSuccess(true);
        setDisableNextBtn(false);
        setShowAlert(false);
      }else{
        setAlertData({
          severity: "warning",
          message: data.data.message,
        });
        setShowAlert(true);
      }

    },
        (error) => {
          setAlertData({
            severity: "warning",
            message:
                error.response &&
                error.response.data &&
                error.response.data.message,
          });
          setShowAlert(true);
        });
  }
  const reqErrorMsg = <span>{t("This field is required")}</span>;
  const invalidDateErrorMsg = <span>{t("Invalid date")}</span>;

  const { values, setFieldValue, handleSubmit, resetForm, errors, touched } =
    useFormik({
      initialValues: {
        fullName: "",
        cid: "",
        dob: null,
        gender: "",
        fatherName: "",
        fatherCid: "",
        motherName: "",
        motherCid: "",
        villageName: "",
        geogName: "",
        dzongkhagName: "",
      },
      onSubmit: () => {
        getCitizenDetail();
      },
      validationSchema: Yup.object().shape({
        cid: Yup.number().required(reqErrorMsg),
        dob: Yup.date(invalidDateErrorMsg).required(reqErrorMsg).nullable(),
      }),
    });

  const [alertData, setAlertData] = useState({ severity: "info", message: "" });
  const [showAlert, setShowAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [deepLinkUrl, setDeepLinkUrl] = useState("");

  useEffect(() => {
    setDisableNextBtn(true);
  }, []);

  const getCitizenDetail = async () => {
    setShowCircularProgress(true);
    setShowAlert(false);
    setPersonalData({
      ...personalData,
      fullName: "",
      gender: "",
      cid: "",
      dob: "",
      fatherName: "",
      fatherCid: "",
      motherName: "",
      motherCid: "",
      villageName: "",
      geogName: "",
      dzongkhagName: "",
    });
    await registerService
      .getCitizenDetail(values.cid, moment(values.dob).format("DD/MM/YYYY"))
      .then(
        (response) => {
          for (const key in response.data) {
            setFieldValue(key, response.data[key]);
            personalData[key] = response.data[key];
          }
          setSuccess(true);
          setPersonalData(personalData);
          setDisableNextBtn(false);
          setFieldValue("cid", values.cid);
          setFieldValue("dob", values.dob);
        },
        (error) => {
          setDisableNextBtn(true);
          setAlertData({
            severity: "warning",
            message:
              error.response &&
              error.response.data &&
              error.response.data.message,
          });
          setShowAlert(true);
        }
      );
    setShowCircularProgress(false);
  };

  const handleChangeCid = (e) => {
    const inputValue = e.target.value;

    if (/^\d{0,11}$/.test(inputValue)) {
      if (inputValue.length > 0 && inputValue[0] !== "1") {
        setShowAlert(true);
        setAlertData({
          severity: "warning",
          message: "This is not a valid Bhutanese CID number.",
        });
      } else {
        setFieldValue("cid", inputValue);
      }
    }
  };

  const BG_COLOR = "#edf7ed";

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Collapse in={showAlert}>
        <Alert
          variant="outlined"
          severity={alertData.severity}
          action={
            <IconButton onClick={() => setShowAlert(false)}>
              <CloseIcon />
            </IconButton>
          }
        >
          <Typography>{alertData.message}</Typography>
        </Alert>
      </Collapse>

      {/*input fields*/}
      <Stack
        // justifyContent="left"
        direction={{ md: "row", xs: "column" }}
        spacing={1}
        gap={1}
        marginTop={1}
      >
        {/* cid */}
        <TextField
          disabled={showCircularProgress}
          value={values.cid}
          placeholder={t("CID")}
          label={t("CID")}
          size="small"
          type="number"
          required
          inputProps={{ autoComplete: "off" }}
          onChange={handleChangeCid}
          error={touched.cid && errors.cid}
          helperText={touched.cid && errors.cid}
        />
        {/* date of birth */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box>
            <DatePicker
              disabled={showCircularProgress}
              inputProps={{ autoComplete: "off" }}
              views={["year", "month", "day"]}
              label={t("Date of Birth")}
              inputFormat="DD/MM/YYYY"
              maxDate={new Date()}
              // emptyLabel="dd/mm/yyyy"
              value={values.dob}
              onChange={(selectedDate) => {
                try {
                  setFieldValue("dob", new Date(selectedDate));
                } catch (err) {}
              }}
              renderInput={(params) => (
                <TextField
                  required
                  disabled={showCircularProgress}
                  size="small"
                  {...params}
                  fullWidth
                  error={touched.dob && errors.dob}
                  helperText={touched.dob && errors.dob}
                />
              )}
            />
            <Box height={5} />
            <Typography variant="body2" sx={{ color: "grey" }}>
              {t("Format")} {": dd/mm/yyyy"}
            </Typography>
          </Box>
        </LocalizationProvider>
        <Box>
          <Button
            disabled={showCircularProgress}
            variant="outlined"
            onClick={() => handleSubmit()}
            type="submit"
            color="info"
            fullWidth
            style={{ textTransform: "initial" }} // Set a fixed height
          >
            <SearchIcon />
            <Typography noWrap>{t("Check my detail")}</Typography>
            {showCircularProgress && <CircularProgress size="1rem" />}
          </Button>
        </Box>

        {/*<Box>*/}
        {/*  <Button*/}
        {/*    fullWidth*/}
        {/*    size="small"*/}
        {/*    onClick={(e) => {*/}
        {/*      setUrl("");*/}
        {/*      setProgressNDI(true);*/}
        {/*      proofNdiRequest();*/}
        {/*      setNdiSignupOpen(true);*/}
        {/*    }}*/}
        {/*    style={{*/}
        {/*      backgroundColor: `#124143`,*/}
        {/*      borderRadius: "17px",*/}
        {/*      textTransform: "initial",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Typography sx={{ color: "#F0F9F4" }}>*/}
        {/*      <img*/}
        {/*        src={NDIlogobg}*/}
        {/*        width="30"*/}
        {/*        height="30"*/}
        {/*        alt="NDI Logo"*/}
        {/*        style={{ marginRight: "10px" }}*/}
        {/*      />*/}
        {/*      Sign Up with Bhutan NDI*/}
        {/*    </Typography>*/}
        {/*  </Button>*/}
        {/*</Box>*/}

        <SignUpNDIScanCode
          url={url}
          ndiSignupOpen={ndiSignupScanOpen}
          setNdiSignupOpen={setNdiSignupOpen}
          isMobile={isMobile}
          deepLinkUrl={deepLinkUrl}
          progressNDI={progressNDI}
        />
      </Stack>
      {/*info cards*/}
      <Typography
        variant="h5"
        style={{ display: personalData.fullName === "" ? "none" : "block" }}
      >
        <Alert severity="info" variant="outlined">
          <Typography>
            {t(
              "These personnel and family information is provided by Department of Civil Registration and Census (DCRC), if the data displayed is incorrect, please update your personnel or family personnel details with DCRC."
            )}
          </Typography>
        </Alert>
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        style={{ display: personalData.fullName === "" ? "none" : "flex" }}
      >
        {/*card 1*/}
        <Box
          // bgcolor={success ? BG_COLOR : undefined}
          flex={1}
          boxShadow={3}
          pt={3}
          borderRadius={2}
          sx={{
            background: "#e0e0e0",
            boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
          }}
        >
          <ul>
            {t("Name")}: {personalData.fullName}
          </ul>
          <ul>
            {t("Gender")}: {personalData.gender}
          </ul>
          <ul>
            {t("CID")}: {personalData.cid}
          </ul>
          <ul>
            {t("Date of Birth")}: {personalData.dob}
          </ul>
        </Box>
        {/*card 2*/}
        <Box
          bgcolor={success ? BG_COLOR : undefined}
          flex={1}
          boxShadow={3}
          pt={3}
          borderRadius={2}
          sx={{
            background: "#e0e0e0",
            boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
          }}
        >
          <ul>
            {t("Father's Name")}: {personalData.fatherName}
          </ul>
          <ul>
            {t("Father's CID")}: {personalData.fatherCid}
          </ul>
          <ul>
            {t("Mother's Name")}: {personalData.motherName}
          </ul>
          <ul>
            {t("Mother's CID")}: {personalData.motherCid}
          </ul>
        </Box>
        {/*card 3*/}
        <Box
          bgcolor={success ? BG_COLOR : undefined}
          flex={1}
          boxShadow={3}
          pt={3}
          borderRadius={2}
          sx={{
            background: "#e0e0e0",
            boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
          }}
        >
          <ul>
            {t("Village")}: {personalData.villageName}
          </ul>
          <ul>
            {t("Geog")}: {personalData.geogName}
          </ul>
          <ul>
            {t("Dzongkhag")}: {personalData.dzongkhagName}
          </ul>
        </Box>
      </Stack>
    </Box>
  );
};

export default SignUpPersonalDetail;
