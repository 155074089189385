import axios from "axios";
import authHeader from "./auth-header";
import authHeaderRefreshToken from "./auth-header-refresh-token";
import { redirect } from "react-router-dom";
import reasonService from "./reason.service";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getUserInfoByCidAndDob = (data) => {
  return axios.post(
    BASE_URL + "api/auth/securitySetting/getUserInfoByCidAndDob",
    data
  );
};

const receiveResetPasswordOtp = (data) => {
  return axios.post(
    BASE_URL + "api/auth/securitySetting/receiveResetPasswordOtp",
    data
  );
};

const verifyResetPwOtp = (data) => {
  return axios.post(
    BASE_URL + "api/auth/securitySetting/verifyResetPwOtp",
    data
  );
};

const resetPasswordUsingMobileNo = (data) => {
  return axios.post(
    BASE_URL + "api/auth/securitySetting/resetPasswordUsingMobileNo",
    data
  );
};

const getPasswordResetLink = (data) => {
  return axios.post(
    BASE_URL + "api/auth/securitySetting/getPasswordResetLink",
    data
  );
};

const validatePasswordResetLink = (data) => {
  return axios.post(
    BASE_URL + "api/auth/securitySetting/validatePasswordResetLink",
    data
  );
};

const resetPasswordUsingEmail = (data) => {
  return axios.post(
    BASE_URL + "api/auth/securitySetting/resetPasswordUsingEmail",
    data
  );
};

const register = (data) => {
  return axios.post(
    BASE_URL + "api/user/profile/signup/signup",
    // "api/user/profile/signup/signup",
    data
  );
};

const login = (username, password, isNDILogin) => {
  return axios
    .post(BASE_URL + "api/auth/auth/v1/login", {
      // "api/auth/auth/v1/login", {
      username,
      password,
      isNDILogin,
    })
    .then(
      (response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      },
      (error) => {
        return error;
      }
    );
};

const regenerateToken = (data) => {
  return axios.post(
    BASE_URL + "api/auth/regenerateToken/v1/regenerateToken",
    data,
    {
      headers: authHeaderRefreshToken(),
    }
  );
};

const logout = async () => {
  localStorage.removeItem("user");
  localStorage.clear();

  return axios
    .post(BASE_URL + "api/auth/auth/v1/logout", [], {
      // "api/auth/auth/v1/logout", [], {
      headers: authHeader(),
    })
    .then(
      (response) => {
        // localStorage.clear();
        return response;
      },
      (error) => {
        // localStorage.clear();
      }
    );

  // return await axios.post(BASE_URL + "api/auth/auth/v1/logout", [], {
  //   // "api/auth/auth/v1/logout", [], {
  //   headers: authHeader(),
  // });
};

const natsNdiRequest = (threadId) => {
  return axios
    .get(BASE_URL + "api/auth/ndi/natsRequest", {
      // .get("api/auth/ndi/natsRequest", {
      params: { threadId },
    })
    .then((response) => {
      return response;
    });
};

export default {
  verifyResetPwOtp,
  getUserInfoByCidAndDob,
  receiveResetPasswordOtp,
  resetPasswordUsingMobileNo,
  getPasswordResetLink,
  validatePasswordResetLink,
  resetPasswordUsingEmail,
  register,
  login,
  regenerateToken,
  logout,
  natsNdiRequest,
};
