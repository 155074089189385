import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";

import { useFormik } from "formik";
import * as Yup from "yup";
import {Box, Stack, Typography, CircularProgress, Chip} from "@mui/material";
import WebsiteHeader from "../Home/WebsiteHeader";
import nsEnrollment from "../../services/ns.registration.service";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";

const TrackApplication = () => {
  const { t, i18n } = useTranslation();

  const blinkingStyle = {
    animation: "blinking 2.5s infinite",
    "@keyframes blinking": {
      "0%": {
        opacity: 1,
      },
      "25%": {
        opacity: 1.5,
      },
      "50%": {
        opacity: 0,
      },
      "75%": {
        opacity: 1.5,
      },
      "100%": {
        opacity: 1,
      },
    },
  };

  const [loading, setLoading] = useState(false);
  const [displayResponse, setDisplayResponse] = useState(false);
  const [applicationDetail, setApplicationDetail] = useState([]);
  const dateFormat = "MMMM DD, YYYY";
  const timeFormat = "hh:mm A";
  const reqLabel = <span>{t("This field is required")}</span>;
  const {
    values: values1,
    handleSubmit: handleTrackSubmit,
    setFieldValue: setFieldValue1,
    touched: touched1,
    errors: errors1,
  } = useFormik({
    initialValues: {
      cid: "",
    },
    onSubmit: () => {
      getApplicantDetail();
    },
    validationSchema: Yup.object().shape({
      cid: Yup.number().required(reqLabel),
    }),
  });

  const getApplicantDetail = async () => {
    setLoading(true);
    setDisplayResponse(false);
    setApplicationDetail([]);
    await nsEnrollment.getApplicantDetail(values1.cid).then(
        (response) => {
          setApplicationDetail(response.data)
          setLoading(false);
          setDisplayResponse(true);
        },
        (error) => {
          setDisplayResponse(true);
        }
    );
    setLoading(false);
  };

  useEffect(() => {
    let language = localStorage.getItem("language");

    if (language === "ENG") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("dz");
    }
  }, []);

  const displayStatusName = (status, mailStatus) => {
    let statusName = (
        <Chip
            size="small"
            label={<Typography>Pending</Typography>}
            color="warning"
            variant="outlined"
        />
    );
    if (status === "C") {
      statusName = (
          <Chip
              size="small"
              label={<Typography>Cancelled</Typography>}
              color="primary"
              variant="outlined"
          />
      );
    } else if (status === "V") {
      statusName = (
          <Chip
              size="small"
              label={<Typography sx={blinkingStyle}>Reverted</Typography>}
              color="error"
              variant="outlined"
          />
      );
    } else if (status === "A" && mailStatus === "S") {
      statusName = (
          <Chip
              size="small"
              label={<Typography>Approved</Typography>}
              color="success"
              variant="outlined"
          />
      );
    } else if (status === "R" && mailStatus === "S") {
      statusName = (
          <Chip
              size="small"
              label={<Typography>Rejected</Typography>}
              color="error"
              variant="outlined"
          />
      );
    }
    else if (status === "T") {
      statusName = (
          <Chip
              size="small"
              label={<Typography>Reviewed</Typography>}
              color="success"
              variant="outlined"
          />
      );
    }
    return statusName;
  };

  const displayEarlyStatusName = (status, mailStatus) => {
    let statusName = (
        <Chip
            size="small"
            label={<Typography>Pending</Typography>}
            color="warning"
            variant="outlined"
        />
    );
    if (status === "C") {
      statusName = (
          <Chip
              size="small"
              label={<Typography>Cancelled</Typography>}
              color="error"
              variant="outlined"
          />
      );
    } else if (status === "V") {
      statusName = (
          <Chip
              size="small"
              label={<Typography sx={blinkingStyle}>Reverted</Typography>}
              color="error"
              variant="outlined"
          />
      );
    } else if (status === "F") {
      statusName = (
          <Chip
              size="small"
              label={<Typography>Forwarded for medical screening</Typography>}
              color="success"
              variant="outlined"
          />
      );
    } else if (status === "R") {
      statusName = (
          <Chip
              size="small"
              label={<Typography>Rejected</Typography>}
              color="error"
              variant="outlined"
          />
      );
    }else if (status === "E") {
      statusName = (
          <Chip
              size="small"
              label={<Typography>Forwarded for Exemption</Typography>}
              color="success"
              variant="outlined"
          />
      );
    }
    return statusName;
  };


  return (
      <>
        <Box bgcolor="white" position={"relative"}>
          <WebsiteHeader initialY={"-10vh"} animateY={"0vh"} />
          <Box
              display="flex"
              justifyContent="center"
              height="100vh"
              alignItems="center"
              position={"relative"}
          >
            <Box
                boxShadow={3}
                borderRadius={2}
                display="flex"
                flexDirection="column"
                width={{ md: "60%", sx: "100%" }}
                // maxHeight="inherit"
                sx={{
                  background: "rgba(255, 255, 255, 0.77)",
                  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                  backdropFilter: "blur(5px)",
                  "*::WebkitBackdropFilter": "blur(5px)",
                  border: "1px solid rgba(255, 255, 255, 1)",
                }}
            >
              <Box
                  p={{ xs: 5, sm: 5 }}
                  display="flex"
                  flexDirection="column"
                  gap={2}
              >
                <>
                  <Stack spacing={1}>
                    <Stack
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                    >
                      <Typography align="center">
                        {t("Track your application using CID")}
                      </Typography>
                      <Box
                          display="flex"
                          flexDirection={{ xs: "column", md: "row" }}
                          width={{ xs: "100%", md: "60%" }}
                          gap={1}
                          justifyContent="center"
                      >
                        <TextField
                            fullWidth
                            required
                            type="number"
                            label={t("CID")}
                            placeholder={t("CID")}
                            size="small"
                            disabled={loading}
                            value={values1.cid}
                            onChange={(e) => setFieldValue1("cid", e.target.value)}
                            helperText={touched1.cid && errors1.cid}
                            error={errors1.cid && touched1.cid}
                            sx={{ width: { xs: "100%", md: "50%" } }}
                        />
                      </Box>

                      <Button
                          disabled={loading}
                          style={{ textTransform: "initial" }}
                          color="info"
                          onClick={() => handleTrackSubmit()}
                          variant="outlined"
                      >
                        <SearchIcon />
                        <Typography>{t("Track")}</Typography>
                        {loading && (
                            <CircularProgress
                                size={30}
                                sx={{
                                  position: "absolute",
                                  p: 1,
                                }}
                            />
                        )}
                      </Button>
                    </Stack>

                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={3}
                        justifyContent="center"
                        alignItems="center"
                    >
                      <Box
                          // p={{ xs: 5, sm: 5 }}
                          display="flex"
                          flexDirection="column"
                          gap={2}
                      >
                        {displayResponse ? (
                            <Table aria-label="simple table">
                              <TableBody>
                                <TableRow>
                                  <TableCell align="right"></TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="right">
                                    <Typography>{t("Sign up")}:</Typography>
                                  </TableCell>
                                  <TableCell>
                                    {applicationDetail.signupDate != null ? (
                                        <Typography>
                                          Signed up on{" "}
                                          {moment(
                                              applicationDetail.signupDate
                                          ).format(dateFormat)}
                                          {", "}
                                          {moment(
                                              applicationDetail.signupDate
                                          ).format(timeFormat)}
                                          {applicationDetail.status}
                                        </Typography>
                                    ) : (
                                        <Typography>N/A</Typography>
                                    )}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell align="right">
                                    <Typography>{t("NS Registration")}:</Typography>
                                  </TableCell>
                                  <TableCell>
                                    {applicationDetail.nsRegistrationDate !=
                                    null ? (
                                        <Typography>
                                          Registered on{" "}
                                          {moment(
                                              applicationDetail.nsRegistrationDate
                                          ).format(dateFormat)}
                                          {", "}
                                          {moment(
                                              applicationDetail.nsRegistrationDate
                                          ).format(timeFormat)}
                                        </Typography>
                                    ) : (
                                        <Typography>N/A</Typography>
                                    )}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell align="right">
                                    <Typography>
                                      {t("Early Enlistment")}:
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    {applicationDetail.eeApplicationDate != null ? (
                                        <>
                                          <Typography>
                                            Registered on{" "}
                                            {moment(
                                                applicationDetail.eeApplicationDate
                                            ).format(dateFormat)}
                                            {", "}
                                            {moment(
                                                applicationDetail.eeApplicationDate
                                            ).format(timeFormat)}
                                          </Typography>
                                          <Typography>
                                            Status: {" "} {displayEarlyStatusName(applicationDetail.eeStatus)}
                                          </Typography>
                                        </>
                                    ) : (
                                        <Typography>N/A</Typography>
                                    )}
                                  </TableCell>
                                </TableRow>

                                {(applicationDetail.specExemptionDate === null ||
                                    applicationDetail.specExemptionDate === undefined) &&<TableRow>
                                  <TableCell align="right">
                                    <Typography>{t("Deferment")}:</Typography>
                                  </TableCell>
                                  <TableCell>
                                    {applicationDetail.defermentApplicationDate !=
                                    null ? (
                                        <>
                                          <Typography>
                                            Applied on{" "}
                                            {moment(
                                                applicationDetail.defermentApplicationDate
                                            ).format(dateFormat)}
                                            {", "}
                                            {moment(
                                                applicationDetail.defermentApplicationDate
                                            ).format(timeFormat)}
                                            {", "}
                                          </Typography>
                                          <Typography>
                                            Status: {" "} {displayStatusName(applicationDetail.defermentStatus,
                                              applicationDetail.defermentMailStatus)}
                                          </Typography>
                                        </>
                                    ) : (
                                        <Typography>N/A</Typography>
                                    )}

                                  </TableCell>
                                </TableRow>}

                                {(applicationDetail.specExemptionDate === null ||
                                    applicationDetail.specExemptionDate === undefined) && <TableRow>

                                  <TableCell align="right">
                                    <Typography>{t("Exemption")}:</Typography>
                                  </TableCell>
                                  <TableCell>
                                    {applicationDetail.exemptionApplicationDate !=
                                    null ? (
                                        <>
                                          <Typography>
                                            Applied on{" "}
                                            {moment(
                                                applicationDetail.exemptionApplicationDate
                                            ).format(dateFormat)}
                                            {", "}
                                            {moment(
                                                applicationDetail.exemptionApplicationDate
                                            ).format(timeFormat)}
                                          </Typography>
                                          <Typography>
                                            Status: {" "} {displayStatusName(applicationDetail.exemptionStatus,
                                              applicationDetail.exemptionMailStatus)}
                                          </Typography>
                                        </>
                                    ) : (
                                        <Typography>N/A</Typography>
                                    )}
                                  </TableCell>
                                </TableRow>}
                                <TableRow>
                                  <TableCell align="right">
                                    <Typography>
                                      {t("Medical Date")}:
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    {applicationDetail.medicalBookingDate != null ? (
                                        <>
                                          <Typography>
                                            <strong>Booked on:</strong>{" "}
                                            {moment(
                                                applicationDetail.medicalBookingDate
                                            ).format(dateFormat)}
                                            {", "}
                                            {moment(
                                                applicationDetail.medicalBookingDate
                                            ).format(timeFormat)}
                                          </Typography>
                                          <Typography>
                                            <strong>Appointment Date:</strong> {" "}
                                            <Chip
                                                size="small"
                                                label={<Typography>{moment(
                                                    applicationDetail.medicalAppointmentDate
                                                ).format(dateFormat)}</Typography>}
                                                color="success"
                                                variant="outlined"
                                            />
                                          </Typography>
                                        </>
                                    ) : (
                                        <Typography>N/A</Typography>
                                    )}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="right">
                                    <Typography>
                                      {t("Medical Completion")}:
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    {applicationDetail.isCompleted != null ? (
                                        <>
                                          <Typography> {applicationDetail.isCompleted === "true" ?  <Chip
                                              size="small"
                                              label={<Typography>Completed</Typography>}
                                              color="success"
                                              variant="outlined"
                                          /> : <Chip
                                              size="small"
                                              label={<Typography>Not Completed</Typography>}
                                              color="error"
                                              variant="outlined"
                                          /> }</Typography>
                                        </>
                                    ) : (
                                        <Typography>N/A</Typography>
                                    )}

                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                        ) : (
                            <></>
                        )}
                      </Box>
                    </Stack>
                  </Stack>
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
  );
};

export default TrackApplication;
