import { Route, Routes, useLocation } from "react-router-dom";
import React, { lazy, Suspense, useEffect, useState } from "react";
import nsRegistrationService from "./services/ns.registration.service";
import NsRegistrationCutOffDate from "./components/Master/NsRegistrationCutOffDate";
import EarlyEnlistementCutOffDate from "./components/Master/EarlyEnlistementCutOffDate";
import ApplyNewEarlyEnlistment from "./components/EarlyEnlistment/ApplyNewEarlyEnlistment";
import UserListAfterRegistration from "./components/Master/UserListAfterRegistration";
import SpecialExemptionList from "./components/SpecialExemption/SpecialExemptionList";
import ApiClient from "./components/Master/ApiClient";

const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));

const ApplyDeferment = lazy(() =>
  import("./components/Deferment/ApplyDeferment")
);

const CancelDeferment = lazy(() =>
  import("./components/Deferment/CancelDeferment")
);

const CancelEarlyEnlistment = lazy(() =>
  import("./components/EarlyEnlistment/CancelEarlyEnlistment")
);

const CancelExemption = lazy(() =>
  import("./components/Exemption/CancelExemption")
);

const AmendDeferment = lazy(() =>
  import("./components/Deferment/AmendDeferment")
);

const AmendExemption = lazy(() =>
  import("./components/Exemption/AmendExemption")
);

const ApplyNewDeferment = lazy(() =>
  import("./components/Deferment/ApplyNewDeferment")
);
const ApplyNewExemption = lazy(() =>
  import("./components/Exemption/ApplyNewExemption")
);
const MyDefermentApplications = lazy(() =>
  import("./components/Deferment/MyDefermentApplications")
);
const MyExemptionApplications = lazy(() =>
  import("./components/Exemption/MyExemptionApplications")
);
const DefermentList = lazy(() =>
  import("./components/Deferment/DefermentList")
);
const ApplyExemption = lazy(() =>
  import("./components/Exemption/ApplyExemption")
);
const ExemptionList = lazy(() =>
  import("./components/Exemption/ExemptionList")
);
const TrainingAcademy = lazy(() => import("./components/TrainingAcademy"));
const PageNotFound = lazy(() =>
  import("./components/PageNotFound/PageNotFound")
);
const EnlistmentDateSetup = lazy(() =>
  import("./components/Master/EnlistmentDateSetup")
);
const ResetUserPassword = lazy(() =>
  import("./components/ResetUserPassword/ResetUserPassword")
);
const TrainingAcademyIntake = lazy(() =>
  import("./components/Master/TrainingAcademyIntake")
);

const NotificationList = lazy(() =>
  import("./components/Notification/NotificationList")
);
const NewNotification = lazy(() =>
  import("./components/Notification/NewNotification")
);
const Reason = lazy(() => import("./components/Master/Reason"));
const RegistrationDateSetup = lazy(() =>
  import("./components/Master/RegistrationDateSetup")
);
const GyalsupProfile = lazy(() =>
  import("./components/UserProfile/GyalsupProfile")
);
const EditCurrentAddress = lazy(() =>
  import("./components/UserProfile/EditCurrentAddress")
);
const EditParentInfo = lazy(() =>
  import("./components/UserProfile/EditParentInfo")
);
const EditGuardianInfo = lazy(() =>
  import("./components/UserProfile/EditGuardianInfo")
);
const EditSocialMediaLink = lazy(() =>
  import("./components/UserProfile/EditSocialMediaLink")
);
const EditCensusRecord = lazy(() =>
  import("./components/UserProfile/EditCensusRecord")
);
const EditMobileNumber = lazy(() =>
  import("./components/UserProfile/EditMobileNumber")
);
const EditEmailAddress = lazy(() =>
  import("./components/UserProfile/EditEmailAddress")
);
const EditPassword = lazy(() =>
  import("./components/UserProfile/EditPassword")
);
const EditUserName = lazy(() =>
  import("./components/UserProfile/EditUserName")
);
const User = lazy(() => import("./components/Master/User"));
const RoleWiseAccessPermission = lazy(() =>
  import("./components/Master/RoleWiseAccessPermission")
);
const Role = lazy(() => import("./components/UserProfile/Role"));
const Policy = lazy(() => import("./components/Master/Policy"));
const ScreenGroupSetup = lazy(() =>
  import("./components/Master/ScreenGroupSetup")
);
const ScreenSetup = lazy(() => import("./components/Master/ScreenSetup"));
const PolicyRoleMapping = lazy(() =>
  import("./components/Master/PolicyRoleMapping")
);
const PolicyUserMapping = lazy(() =>
  import("./components/Master/PolicyUserMapping")
);
const PolicyStatementMapping = lazy(() =>
  import("./components/Master/PolicyStatementMapping")
);
const AutoExemption = lazy(() =>
  import("./components/autoExemption/AutoExemption")
);
const EligiblePopulationList = lazy(() =>
  import("./components/Dashboard/EligiblePopulationList")
);
const NotSignUpPopulationList = lazy(() =>
  import("./components/Dashboard/NotSignUpPopulationList")
);
const EligibleMalePopulationList = lazy(() =>
  import("./components/Dashboard/EligibleMalePopulationList")
);
const EligibleFemalePopulationList = lazy(() =>
  import("./components/Dashboard/EligibleFemalePopulationList")
);
const ViewApplicantProfile = lazy(() =>
  import("./components/ViewApplicantProfile")
);
const SignupPopulationList = lazy(() =>
  import("./components/Dashboard/SignupPopulationList")
);
const RegisteredList = lazy(() =>
  import("./components/Dashboard/RegisteredList")
);
const EarlyEnlistmentListDashBoard = lazy(() =>
  import("./components/Dashboard/EarlyEnlistmentListDashBoard")
);
const DeferredList = lazy(() => import("./components/Dashboard/DeferredList"));
const ExemptedList = lazy(() => import("./components/Dashboard/ExemptedList"));
const AgeCriteria = lazy(() => import("./components/Master/AgeCriteria"));
const TrainingDate = lazy(() => import("./components/Master/TrainingDate"));
const OpenUserDashboard = lazy(() =>
  import("./components/OpenUserDashboard/OpenUserDashboard")
);
const SettingDashboard = lazy(() =>
  import("./components/OpenUserDashboard/SettingDashboard")
);

const BookAppointment = lazy(() =>
  import("./components/MedicalBooking/BookAppointment")
);
const MedicalConfiguration = lazy(() =>
  import("./components/MedicalBooking/MedicalConfiguration")
);
const EarlyEnlistmentList = lazy(() =>
  import("./components/EarlyEnlistment/EarlyEnlistmentList")
);
const NSRegistration = lazy(() =>
  import("./components/NSRegistration/NSRegistration")
);
const NSRegistrationList = lazy(() =>
  import("./components/NSRegistration/NSRegistrationList")
);
const ApplyEarlyEnlistment = lazy(() =>
  import("./components/EarlyEnlistment/ApplyEarlyEnlistment")
);
const DefermentListUx = lazy(() => import("./components/ux/DefermentListUx"));
const ExemptionListUx = lazy(() => import("./components/ux/ExemptionListUx"));
const DefermentDetails = lazy(() =>
  import("./components/Deferment/DefermentDetails")
);
const ExemptionDetails = lazy(() =>
  import("./components/Exemption/ExemptionDetails")
);

const RemainingNotRegisteredList = lazy(() =>
  import("./components/Dashboard/RemainingNotRegisteredList")
);

const NewMedicalSelfDeclaration = lazy(() =>
  import("./components/MedicalBooking/NewMedicalSelfDeclaration")
);

const NewMentalHealthSelfDeclaration = lazy(() =>
  import("./components/MedicalBooking/NewMentalHealthSelfDeclaration")
);

const MyMedicalDashboard = lazy(() =>
  import("./components/MedicalBooking/MyMedicalDashboard")
);
const ViewMedicalSelfDeclaration = lazy(() =>
  import("./components/MedicalBooking/ViewMedicalSelfDeclaration")
);

const MyEarlyEnlistmentApplications = lazy(() =>
  import("./components/EarlyEnlistment/MyEarlyEnlistmentApplications")
);

const AmendEarlyEnlistment = lazy(() =>
  import("./components/EarlyEnlistment/AmendEarlyEnlistment")
);

const EarlyEnlistmentDetails = lazy(() =>
  import("./components/EarlyEnlistment/EarlyEnlistmentDetails")
);

const SpecialExemptionDetails = lazy(() =>
    import("./components/SpecialExemption/SpecialExemptionDetails")
);
const SpecialExemptionBulk = lazy(() =>
    import("./components/SpecialExemption/SpecialExemptionBulk")
);

const MedicalConfigurationBulk = lazy(() =>
    import("./components/MedicalBooking/MedicalConfigurationBulk")
);
const DashboardMedicalBookingList = lazy(() =>
    import("./components/MedicalBooking/DashboardMedicalBookingList")
);

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // try to import the component
    componentImport()
      .then((component) => {
        resolve(component);
      })
      .catch((error) => {
        // TO DO
        reject(error); // there was an error
      });
  });
};

const DashboardDefermentList = lazy(() =>
  import("./components/Deferment/DashboardDefermentList")
);

const DashboardExemptionList = lazy(() =>
  import("./components/Exemption/DashboardExemptionList")
);

const NSRegistrationVerification = lazy(() =>
    import("./components/NSRegistration/NSRegistrationVerification")
);

const EarlyEnlistmentVerification = lazy(() =>
    import("./components/EarlyEnlistment/EarlyEnlistmentVerification")
);

const routePathMapping = {
  "/policy": <Policy />,
  "/policyRoleMapping": <PolicyRoleMapping />,
  "/policyUserMapping": <PolicyUserMapping />,
  "/policyStatementMapping": <PolicyStatementMapping />,
  "/applyDeferment": <ApplyDeferment />,
  "/myDefermentApplications": <MyDefermentApplications />,
  "/applyNewDeferment": <ApplyNewDeferment />,
  "/trainingAcademyIntake": <TrainingAcademyIntake />,
  "/dashboard": <Dashboard />,
  "/gyalsungRegistrationDateSetup": <RegistrationDateSetup />,
  "/trainingAcademy": <TrainingAcademy />,
  "/users": <User />,
  "/apiClient": <ApiClient />,
  "/exemptionList": <ExemptionList />,
  "/resetUserPassword": <ResetUserPassword />,
  "/roleWiseAccessPermission": <RoleWiseAccessPermission />,
  "/notificationList": <NotificationList />,
  "/roleSetup": <Role />,
  "/screenGroupSetup": <ScreenGroupSetup />,
  "/ageCriteria": <AgeCriteria />,
  "/trainingDate": <TrainingDate />,
  "/nsRegistrationCutOffDate": <NsRegistrationCutOffDate />,
  "/earlyEnlistmentCutOffDate": <EarlyEnlistementCutOffDate />,
  "/enlistmentDateSetup": <EnlistmentDateSetup />,
  "/applyExemption": <ApplyExemption />,
  "/defermentAndExemptionReason": <Reason />,
  "/gyalsupProfile": <GyalsupProfile />,
  "/editCurrentAddress": <EditCurrentAddress />,
  "/editParentInfo": <EditParentInfo />,
  "/editGuardianInfo": <EditGuardianInfo />,
  "/editSocialMediaLink": <EditSocialMediaLink />,
  "/editCensusRecord": <EditCensusRecord />,
  "/editMobileNumber": <EditMobileNumber />,
  "/editEmailAddress": <EditEmailAddress />,
  "/editPassword": <EditPassword />,
  "/editUserName": <EditUserName />,
  "/screenSetup": <ScreenSetup />,
  "/autoExemption": <AutoExemption />,
  "/defermentList": <DefermentList />,
  "/eligiblePopulationList": <EligiblePopulationList />,
  "/eligibleMalePopulationList": <EligibleMalePopulationList />,
  "/eligibleFemalePopulationList": <EligibleFemalePopulationList />,
  "/registeredList": <RegisteredList />,
  "/earlyEnlistmentListDashBoard": <EarlyEnlistmentListDashBoard />,
  "/deferredList": <DeferredList />,
  "/exemptedList": <ExemptedList />,
  "/screenGroup": <ScreenGroupSetup />,
  "/viewApplicantProfile": <ViewApplicantProfile />,
  "/signupPopulationList": <SignupPopulationList />,
  "/openUserDashboard": <OpenUserDashboard />,
  "/settingDashboard": <SettingDashboard />,
  "/bookAppointment": <BookAppointment />,
  "/earlyEnlistmentList": <EarlyEnlistmentList />,
  "/applyEarlyEnlistment": <ApplyEarlyEnlistment />,
  "/nsRegistration": <NSRegistration />,
  "/nsRegistrationList": <NSRegistrationList />,
  "/medicalConfiguration": <MedicalConfiguration />,
  "/defermentListUx": <DefermentListUx />,
  "/exemptionListUx": <ExemptionListUx />,
  "/myExemptionApplications": <MyExemptionApplications />,
  "/newMedicalSelfDeclaration": <NewMedicalSelfDeclaration />,
  "/myEarlyEnlistmentApplications": <MyEarlyEnlistmentApplications />,
  "/newMentalHealthSelfDeclaration": <NewMentalHealthSelfDeclaration />,
  "/myMedicalDashboard": <MyMedicalDashboard />,
  "/viewMedicalSelfDeclaration": <ViewMedicalSelfDeclaration />,
  "/userListAfterRegistration": <UserListAfterRegistration />,
  "/specialExemptionList": <SpecialExemptionList />,
};

const RoutePaths = () => {
  const location = useLocation();
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    let tmp = [];
    const tL = [];
    const userData = JSON.parse(localStorage.getItem("user"));
    let scrn = userData.accessScreens;
    const userType = userData.roles.filter((item) => {
      return item.userType === "S";
    });
    for (const i in scrn) {
      if (!tL.includes(scrn[i]["screen_name"])) {
        tmp.push({ [scrn[i]["screen_name"]]: scrn[i]["screen_url"] });
        tL.push(scrn[i]["screen_name"]);
      }
    }
    if (userType.length > 0) {
      tmp.push({ "Open User Dashboard": "openUserDashboard" });
      tmp.push({ "Open User setting dashboard": "settingDashboard" });
      tL.push("Open User Dashboard");
    }

    setRoutes(tmp);
    // nsRegistrationService
    //   .checkUserAlreadyRegistered(userData.userId)
    //   .then((response) => {
    //     if (!response.data) {
    //       setRoutes(
    //         tmp.filter(
    //           (item) =>
    //             item["Apply Deferment"] !== "applyDeferment" &&
    //             item["Apply Exemption"] !== "applyExemption"
    //         )
    //       );
    //     }
    //   });
  }, []);

  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <Routes location={location} key={location.pathname}>
        {routes.map((item) => {
          return (
            <Route
              key={Object.keys(item)[0]}
              path={item[Object.keys(item)[0]]}
              element={routePathMapping[`/${Object.values(item)[0]}`]}
            />
          );
        })}
        {/*<Route path={""} element={<Dashboard />} />*/}
        <Route path={"dashboard/registeredList"} element={<RegisteredList />} />
        <Route
          path={"dashboard/earlyEnlistmentList"}
          element={<EarlyEnlistmentList />}
        />
        <Route path={"dashboard/deferredList"} element={<DeferredList />} />
        <Route path={"dashboard/exemptedList"} element={<ExemptedList />} />
        <Route path={"dashboard/defermentList"} element={<DefermentList />} />
        <Route
          path={"dashboard/dashboardDefermentList"}
          element={<DashboardDefermentList />}
        />
        <Route
          path={"dashboard/dashboardExemptionList"}
          element={<DashboardExemptionList />}
        />

        <Route path={"dashboard/exemptionList"} element={<ExemptionList />} />
        <Route
          path={"dashboard/eligiblePopulationList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/earlyEnlistmentListDashBoard/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/notSignUpPopulationList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/eligibleMalePopulationList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/eligibleFemalePopulationList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/signupPopulationList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />

        <Route
          path={"dashboard/registeredList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/earlyEnlistmentList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/deferredList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/exemptedList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/remainingNotRegisteredList"}
          element={<RemainingNotRegisteredList />}
        />
        <Route
          path={"nsRegistrationList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/nsRegistrationList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"exemptionList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"defermentList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"earlyEnlistmentList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/dashboardDefermentList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/dashboardExemptionList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/remainingNotRegisteredList/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />
        <Route
          path={"dashboard/signupPopulationList"}
          element={<SignupPopulationList />}
        />
        <Route
          path={"dashboard/nsRegistrationList"}
          element={<NSRegistrationList />}
        />
        <Route
          path={"dashboard/eligiblePopulationList"}
          element={<EligiblePopulationList />}
        />
        <Route
          path={"dashboard/eligibleMalePopulationList"}
          element={<EligibleMalePopulationList />}
        />
        <Route
          path={"dashboard/eligibleFemalePopulationList"}
          element={<EligibleFemalePopulationList />}
        />
        <Route
          path={"dashboard/earlyEnlistmentListDashBoard"}
          element={<EarlyEnlistmentListDashBoard />}
        />
        <Route
          path={"gyalsupProfile/editCurrentAddress"}
          element={<EditCurrentAddress />}
        />
        <Route
          path={"gyalsupProfile/editParentInfo"}
          element={<EditParentInfo />}
        />
        <Route
          path={"gyalsupProfile/editGuardianInfo"}
          element={<EditGuardianInfo />}
        />
        <Route
          path={"gyalsupProfile/editSocialMediaLink"}
          element={<EditSocialMediaLink />}
        />
        <Route
          path={"gyalsupProfile/editMobileNumber"}
          element={<EditMobileNumber />}
        />
        <Route
          path={"myMedicalDashboard/bookAppointment/editMobileNumber"}
          element={<EditMobileNumber />}
        />
        <Route
          path={"gyalsupProfile/editEmailAddress"}
          element={<EditEmailAddress />}
        />
        <Route
          path={"myMedicalDashboard/bookAppointment/editEmailAddress"}
          element={<EditEmailAddress />}
        />
        <Route
          path={"gyalsupProfile/editUserName"}
          element={<EditUserName />}
        />
        <Route
          path={"gyalsupProfile/editPassword"}
          element={<EditPassword />}
        />
        <Route
          path={"defermentList/defermentDetails"}
          element={<DefermentDetails />}
        />
        <Route
          path={"defermentList/defermentDetails/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />

        <Route
          path={"exemptionList/exemptionDetails"}
          element={<ExemptionDetails />}
        />

        <Route
          path={"exemptionList/exemptionDetails/viewApplicantProfile"}
          element={<ViewApplicantProfile />}
        />

        <Route
          path={"myDefermentApplications/applyNewDeferment"}
          element={<ApplyNewDeferment />}
        />
        <Route
          path={"myExemptionApplications/applyNewExemption"}
          element={<ApplyNewExemption />}
        />
        <Route
          path={"myDefermentApplications/cancelDeferment"}
          element={<CancelDeferment />}
        />
        <Route
          path={"myExemptionApplications/cancelExemption"}
          element={<CancelExemption />}
        />
        <Route
          path={"myDefermentApplications/applyNewDeferment/cancelDeferment"}
          element={<CancelDeferment />}
        />
        <Route
          path={"myExemptionApplications/applyNewExemption/cancelExemption"}
          element={<CancelExemption />}
        />
        <Route
          path={"myDefermentApplications/amendDeferment"}
          element={<AmendDeferment />}
        />
        <Route
          path={"myExemptionApplications/amendExemption"}
          element={<AmendExemption />}
        />
        <Route
          path={"myDefermentApplications/applyNewDeferment/amendDeferment"}
          element={<AmendDeferment />}
        />
        <Route
          path={"myExemptionApplications/applyNewExemption/amendExemption"}
          element={<AmendExemption />}
        />
        <Route
          path={"dashboard/notSignUpPopulationList"}
          element={<NotSignUpPopulationList />}
        />
        <Route
          path={"notificationList/newNotification"}
          element={<NewNotification />}
        />
        <Route
          path={"myMedicalDashboard/newMentalHealthSelfDeclaration"}
          element={<NewMentalHealthSelfDeclaration />}
        />
        <Route
          path={
            "myMedicalDashboard/newMedicalSelfDeclaration/newMentalHealthSelfDeclaration"
          }
          element={<NewMentalHealthSelfDeclaration />}
        />
        <Route
          path={"myMedicalDashboard/newMedicalSelfDeclaration"}
          element={<NewMedicalSelfDeclaration />}
        />
        <Route
          path={
            "myMedicalDashboard/newMentalHealthSelfDeclaration/newMedicalSelfDeclaration"
          }
          element={<NewMedicalSelfDeclaration />}
        />
        <Route
          path={
            "myMedicalDashboard/viewMedicalSelfDeclaration/newMedicalSelfDeclaration"
          }
          element={<NewMedicalSelfDeclaration />}
        />
        <Route
          path={"myMedicalDashboard/bookAppointment"}
          element={<BookAppointment />}
        />
        <Route
          path={"myMedicalDashboard/viewMedicalSelfDeclaration"}
          element={<ViewMedicalSelfDeclaration />}
        />
        <Route
          path={"myEarlyEnlistmentApplications/applyNewEarlyEnlistment"}
          element={<ApplyNewEarlyEnlistment />}
        />
        <Route
          path={"myEarlyEnlistmentApplications/amendEarlyEnlistment"}
          element={<AmendEarlyEnlistment />}
        />
        <Route
          path={"earlyEnlistmentList/earlyEnlistmentDetails"}
          element={<EarlyEnlistmentDetails />}
        />
        <Route
          path={"myEarlyEnlistmentApplications/cancelEarlyEnlistment"}
          element={<CancelEarlyEnlistment />}
        />
        <Route
            path={"specialExemptionList/specialExemptionDetails"}
            element={<SpecialExemptionDetails />}
        />
        <Route
            path={"specialExemptionList/specialExemptionBulk"}
            element={<SpecialExemptionBulk />}
        />
        <Route
            path={"medicalConfiguration/medicalConfigurationBulk"}
            element={<MedicalConfigurationBulk />}
        />
        <Route
            path={"dashboard/dashboardMedicalBookingList"}
            element={<DashboardMedicalBookingList />}
        />
        <Route
            path={"dashboard/dashboardMedicalBookingList/viewApplicantProfile"}
            element={<ViewApplicantProfile />}
        />
        <Route
            path={"nsRegistration/nsRegistrationVerification"}
            element={<NSRegistrationVerification />}
        />

        <Route
            path={"myEarlyEnlistmentApplications/applyNewEarlyEnlistment/editGuardianInfo"}
            element={<EditGuardianInfo />}
        />

        <Route
            path={"myEarlyEnlistmentApplications/applyNewEarlyEnlistment/applyEarlyEnlistment"}
            element={<ApplyEarlyEnlistment />}
        />

        <Route
            path={"myEarlyEnlistmentApplications/applyNewEarlyEnlistment/applyEarlyEnlistment/earlyEnlistmentVerification"}
            element={<EarlyEnlistmentVerification />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RoutePaths;
